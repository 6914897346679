import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  canActivate(): Promise<boolean> {
    return this.authService.getCurrentUser().then(user => {
      if(!user) {
        this.router.navigate(['/auth/login']);
        return false;
      } else {
        return true;
      }
    });
  }


  constructor(
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    private router: Router
  ) { }

}