import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private toastrService: NbToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let body = "ERROR";

        if (error.error && !(error.error instanceof ProgressEvent)) {
          body = error.error.message;
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          body = error.message;
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(`Backend returned code ${error.status}: ${error.message}, body was: ${error.error}`);
        }

        setTimeout(() => {
          this.toastrService.show(
            body,
            "this.translate.instant('UI.MESSAGE.SORRY')",
            { status: "danger" });
        }, 100);

        return throwError(error);
      })
    );
  }
}
