import { Component, ViewEncapsulation, AfterContentInit } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { AuthProvider } from 'ngx-auth-firebaseui';


/**
 * Component for login
 */
@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {

  providers = AuthProvider;


  constructor(private router: Router) {
  }

  succesLogin(event) {
    this.router.navigate(['/pages/dashboard']);
  }
}
