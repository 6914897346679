import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IRequestBodyOptions {
  headers?: HttpHeaders;
  observe?: 'body' | 'response';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

/**
 * A service for preparing http requests
 */
@Injectable()
export class HttpService {

  private readonly apiUrl = 'http://localhost:7880/api';
  private readonly absoluteUrlPattern = /^https?:\/\//i;

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Add context & API prefix for string URLs
   *
   * @param url the string URL
   * @return {string} prefixed URL
   */
  private prefixUrl(url: string): string {
    if (!this.absoluteUrlPattern.test(url)) {
      // add context name prefix
      return `${this.apiUrl}${url}`;
    } else {
      // original url
      return url;
    }
  }

  get<T>(url: string, options?: IRequestBodyOptions): Observable<T> {
    return this.httpClient.get<T>(this.prefixUrl(url), options as Object);
  }

  post<T>(url: string, body: any, options?: IRequestBodyOptions): Observable<T> {
    return this.httpClient.post<T>(this.prefixUrl(url), body, options as Object);
  }

  put<T>(url: string, body: any, options?: IRequestBodyOptions): Observable<T> {
    return this.httpClient.put<T>(this.prefixUrl(url), body, options as Object);
  }

  delete<T>(url: string, options?: IRequestBodyOptions): Observable<T> {
    return this.httpClient.request('delete', this.prefixUrl(url), options as Object)
      .pipe(map(res => res as T));
  }
}
