import { Injectable, Injector } from '@angular/core';
import { tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NbTokenService, NbAuthService } from '@nebular/auth';
import { of } from 'rxjs';
import { User } from 'firebase';

/**
 * Configuration object that is populated on application initialization using values from the backend
 */
@Injectable()
export class AppConfig {

  private _currentUser: User;

  constructor(private injector: Injector) {
  }

  get currentUser(): User {
    return Object.assign({}, this._currentUser);
  }

  load(): Promise<any> {
    return this.authService.getToken().pipe(
      switchMap(token => {
        return of();
      })
    ).toPromise();
  }

  protected get tokenService(): NbTokenService {
    return this.injector.get(NbTokenService);
  }
  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }
  protected get router(): Router {
    return this.injector.get(Router);
  }
}
