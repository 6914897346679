import { Component, OnInit } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { AppConfig } from '../../../app-config';

/**
 * An intermediate page for loading logged in user after log in
 */
@Component({
  selector: 'ngx-successful-login',
  templateUrl: './successful-login.component.html',
  styleUrls: ['./successful-login.component.scss']
})
export class SuccessfulLoginComponent implements OnInit {

  constructor(private authService: NbAuthService, private appConfig: AppConfig, private router: Router) { }

  ngOnInit() {
    this.authService.onTokenChange().subscribe(token => {
      if (token.isValid()) {
        this.appConfig.load().then(() => {
          setTimeout(() => {
            this.router.navigate(['']);
          }, 100);
        });
      } else {
        this.router.navigate(['auth/logout']);
      }
    });
  }

}
