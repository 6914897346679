import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { NbAuthModule } from '@nebular/auth';
import { SuccessfulLoginComponent } from './successful-login/successful-login.component';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';


@NgModule({
  declarations: [
    LoginComponent,
    SuccessfulLoginComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    NbAuthModule,
    NgxAuthFirebaseUIModule
  ]
})
export class AuthModule { }
